module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":750,"linkImagesToOriginal":false,"wrapperStyle":"margin-bottom: 1.0725rem;"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Greenspan Humphrey Weinstein LLP","short_name":"GHW","description":"Greenspan Humphrey Weinstein LLP practices criminal and related regulatory and administrative litigation.","homepage_url":"https://gatstrap.netlify.com","start_url":"/","background_color":"#000","theme_color":"#2e2e7d","display":"standalone","icons":[{"src":"/img/favicon/android-icon-192x192.png","sizes":"192x192","type":"image/png"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-54647822-6"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
